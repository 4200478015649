import MicroApp from "./micro-app-base";
import HuitabHeader from "../common/huitab-header";
import { HuitabPageManager } from "../../services/page-manager";

/**
 * Microapp aide et contact
 */
export default class AideEtContactMicroApp extends MicroApp {
  /**
   * Surcharge pour réagir aux messages de la microapp
   */
  childPymEvents = {
    ActivationDemandeEnLigne: function () {
      HuitabPageManager.updateUrlFromIdTab("Demandes");
      HuitabHeader.setFilArianeDesktop([
        { label: "Aide et contact" },
        { label: "Tableau de bord", href: "/" },
        { label: "Demande en ligne" },
      ]);
    },
    ActivationNumerosUtiles: function () {
      HuitabPageManager.updateUrlFromIdTab("Numeros-liens");
      HuitabHeader.setFilArianeDesktop([
        { label: "Aide et contact" },
        { label: "Tableau de bord", href: "/" },
        { label: "Numéros et liens utiles" },
      ]);
    },
  };

  /**
   * Initialise une nouvelle instance de type {@type AideEtContactMicroApp}.
   */
  constructor() {
    super();
  }
}
