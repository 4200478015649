import MicroApp from './micro-app-base';
import { huitabSettings } from "../../services/settings-loader";

/**
 * Microapp widget coupures
 */
export default class WidgetAideEtContactMicroApp extends MicroApp {

  /**
   * Initialise une nouvelle instance de type {@type WidgetAideEtContactMicroApp}.
   */
  constructor() {
    super();
    this.url = huitabSettings.url.widget_mes_demandes_colloc_url;
    this.urlVie = huitabSettings.url.widget_mes_demandes_colloc_url_vie;
  }
}
