import IframeBase from './iframe-base.js';
import { HuitabPageManager, PageManager } from "../../services/page-manager";
import { huitabSettings, PageTypes } from "../../services/settings-loader";

/**
 * La configuration pour réagir à l'event Afficher
 */
const AFFICHER_PAGE = {
  AfficherEspaceDynamique: () => {
    HuitabPageManager.openLinkInNewTab(huitabSettings.url.esp_dynamique_url);
  },
  AfficherPortailDeveloppeur: () => {
    PageManager.redirectTo(PageTypes.MES_ACCES_API);
  },
  detailInfoCoupures: () => {
    PageManager.redirectTo(PageTypes.INFO_COUPURES);
  },
  accueil: () => {
    PageManager.redirectTo(PageTypes.RACINE);
  },
  mesCompteurs: () => {
    PageManager.redirectTo(PageTypes.MES_DONNEES_ENERGETIQUES);
  },
  gestionCompte: idTab => {
    if (!idTab) {
      PageManager.redirectTo(PageTypes.GESTION_COMPTE);
    } else {
      HuitabPageManager.openLink("/gestion-compte?idTab=" + idTab);
    }
  },
};

/**
 * Microapp générique
 */
export default class MicroApp extends IframeBase {
  /**
   * Les events génériques envoyés par les microapps au portail
   * @type {*}
   */
  pymEvents = {
    /**
     * Configurer la hauteur de l'iframe à la demande de la microapp
     * @param {string} height La hauteur de l'iframe
     */
    height: height => {
      this.style.height = height + "px";
    },
    /**
     * Scrolle jusqu'en haut d'un élément (à partir de son id) dans une iframe
     * @param {string} id id html de l'élément vers lequel scroller
     */
    scrollToElement: id => {
      const element = this.firstChild.contentWindow.document.getElementById(id);
      if (element) {
        element.scrollIntoView();
      }
    },
    /**
     * Scroll en haut de la page à la demande de la microapp
     */
    scrollToTop: () => {
      window.scrollTo(0, jQuery(this).offset().top);
    },
    /**
     * Déconnexion de l'utilisateur à la demande de la microapp
     */
    deconnexionUser: () => {
      PageManager.logout();
    },
    /**
     * Afficher une page à la demande de la microapp
     * @param {string} msg la page à afficher au format page.params
     */
    Afficher: msg => {
      const parts = msg.split(".");
      const key = parts[0];
      const value = parts[1];

      if (AFFICHER_PAGE[key]) {
        AFFICHER_PAGE[key](value);
      } else {
        console.error("MICRO_APP_AFFICHER", msg);
      }
    },
    /**
     * Action pour tester l'intégration entre le portail et la microapp
     */
    test: msg => {
      alert(msg);
    },
  };

  /**
   * Les events spécifique à une microapp, à surcharger dans les classes héritant de {@type MicroApp}
   * @type {*}
   */
  childPymEvents = {};

  /**
   * Initialise une nouvelle instance de type {@type MicroApp}.
   */
  constructor() {
    super();
  }

  /**
   * Affiche l'iframe et ajoute les événements pymjs
   * @override
   */
  displayOnReady() {
    if (this.pymParent) {
      this.pymParent.remove();
    }
    this.pymParent = new pym.Parent(this.id, this.url, this.config);
    this.setPymEvents();
  }

  /**
   * Ajoute la gestion des événements pymjs
   */
  setPymEvents() {
    const pym_events = Object.assign({}, this.pymEvents, this.childPymEvents);
    for (const key in pym_events) {
      if (pym_events.hasOwnProperty(key)) {
        this.pymParent.onMessage(key, msg => {
          console.log("MICRO_APP", key, msg);
          pym_events[key](msg);
        });
      }
    }
  }

  /**
   * Envoie un message pymjs
   */
  pymSendMessage(key, value) {
    if (typeof this.pymParent !== "undefined") {
      this.pymParent.sendMessage(key, value);
    }
  }
}
