import AvatarContent from "./avatar/avatar-content.js";
import AvatarWidget from "./avatar/avatar-widget.js";
import HuitabAnchor from "./common/huitab-anchor.js";
import HuitabHeader from "./common/huitab-header.js";
import IframeBase from "./micro-apps/iframe-base.js";
import AideEtContactMicroApp from "./micro-apps/micro-app-aide-et-contact.js";
import MicroApp from "./micro-apps/micro-app-base.js";
import CompteursMicroApp from "./micro-apps/micro-app-compteurs.js";
import FaqDocumentationMicroApp from "./micro-apps/micro-app-faq-documentation.js";
import GestionCompteMicroApp from "./micro-apps/micro-app-gestion-compte.js";
import MigrationCompteMicroApp from "./micro-apps/micro-app-migration-compte.js";
import PortailDevMicroApp from "./micro-apps/micro-app-portail-dev.js";
import WidgetCoupuresMicroApp from "./micro-apps/micro-app-widget-coupures.js";
import ActualitesMicroApp from "./micro-apps/micro-app-actualites.js";
import ActualitesWidgetMicroApp from "./micro-apps/micro-app-actualites-widget.js";
import CartographieReseauMicroApp from "./micro-apps/micro-app-cartographie-reseau.js";
import ModalCgu from "./modal/modal-cgu.js";
import ModalExternalLink from "./modal/modal-extenal-link.js";
import ModalLogout from "./modal/modal-logout.js";
import ModalMigrationAMigrer from "./modal/modal-migration-a-migrer.js";
import ModalMigrationEnCours from "./modal/modal-migration-en-cours.js";
import WidgetMesures from "./widgets/widget-mesures.js";
import WidgetTravaux from "./widgets/widget-travaux.js";
import WidgetAideEtContactMicroApp from "./micro-apps/micro-app-widget-aide-et-contact.js";

/**
 * Définition des custom elements
 */
// Common
customElements.define("huitab-anchor", HuitabAnchor, { extends: "a" });
customElements.define("huitab-header", HuitabHeader, { extends: "header" });

// Iframe et microapps
customElements.define("micro-app", MicroApp, { extends: "div" });
customElements.define("micro-app-gestion-compte", GestionCompteMicroApp, { extends: "div" });
customElements.define("micro-app-migration-compte", MigrationCompteMicroApp, { extends: "div" });
customElements.define("micro-app-widget-coupures", WidgetCoupuresMicroApp, { extends: "div" });
customElements.define("micro-app-compteurs", CompteursMicroApp, { extends: "div" });
customElements.define("micro-app-portail-dev", PortailDevMicroApp, { extends: "div" });
customElements.define("micro-app-iframe-base", IframeBase, { extends: "div" });
customElements.define("micro-app-faq-documentation", FaqDocumentationMicroApp, { extends: "div" });
customElements.define("micro-app-aide-et-contact", AideEtContactMicroApp, { extends: "div" });
customElements.define("micro-app-actualites-widget", ActualitesWidgetMicroApp, { extends: "div" });
customElements.define("micro-app-actualites", ActualitesMicroApp, { extends: "div" });
customElements.define("micro-app-cartographie-reseau", CartographieReseauMicroApp, { extends: "div" });
customElements.define("micro-app-widget-aide-et-contact", WidgetAideEtContactMicroApp, { extends: "div" });


// Modals
customElements.define("modal-enedis-migration-encours", ModalMigrationEnCours, { extends: "div" });
customElements.define("modal-enedis-migration-amigrer", ModalMigrationAMigrer, { extends: "div" });
customElements.define("modal-enedis-cgu", ModalCgu, { extends: "div" });
customElements.define("modal-logout", ModalLogout, { extends: "div" });
customElements.define("modal-external-link", ModalExternalLink, { extends: "div" });

// Widgets
customElements.define("widget-travaux", WidgetTravaux, { extends: "div" });
customElements.define("widget-mesures", WidgetMesures, { extends: "a" });

// Avatar
customElements.define("avatar-content", AvatarContent, { extends: "div" });
customElements.define("avatar-wrapper", AvatarWidget, { extends: "div" });
